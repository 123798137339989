import { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import CustomButton from "../Components/CustomButton";
import { SessionContext } from "../Contexts/SessionContext";
import { SocketContext } from "../Contexts/SocketContext";
import Divider from "../Components/Divider";

const IncorrectPasswordSwal = {
    customClass: {
        title: "text-light",
    },
    timer: 1500,
    showConfirmButton: false,
    toast: true,
    position: "top",
    background: "#303030",
    title: "Helytelen jelszó",
    icon: "error",
};

export default function Login() {
    const { setAuthenticated } = useContext(SessionContext);
    const { socket } = useContext(SocketContext);

    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const onLoginResponse = useCallback((authenticated) => {
        setAuthenticated(authenticated);
        if (authenticated) {
            navigate("/admin");
        } else {
            setPassword("");
            Swal.fire(IncorrectPasswordSwal);
        }
    }, []);

    useEffect(() => {
        socket.on("login", onLoginResponse);
        return () => {
            socket.off("login", onLoginResponse);
        };
    }, []);

    return (
        <>
            <Divider>Bejelentkezés</Divider>
            <div className='mx-3'>
                <input
                    className='bg-transparent border rounded border-warm border-3 p-1 text-warm h3 text-center mb-4'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder='Jelszó'
                ></input>
                <CustomButton
                    text={"Bejelentkezés"}
                    hot={true}
                    onClick={() => password.length > 0 && socket.emit("login", password)}
                />
            </div>
        </>
    );
}
