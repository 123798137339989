import { useContext, useEffect, useState } from "react";
import { push as Menu } from "react-burger-menu";
import {
    FaTicketAlt,
    FaKey,
    FaSignOutAlt,
    FaToggleOn,
    FaPlusCircle,
    FaTemperatureLow,
} from "react-icons/fa";
import { Link } from "react-router-dom";

import "./Sidebar.css";
import logo from "../logo.png";
import CustomButton from "../Components/CustomButton";
import { SessionContext } from "../Contexts/SessionContext";
import { SocketContext } from "../Contexts/SocketContext";

export default function Sidebar({ ...props }) {
    const { authenticated } = useContext(SessionContext);
    const { isSocketConnected, socket } = useContext(SocketContext);

    const [manualModeEnabled, setManualModeEnabled] = useState(false);

    useEffect(() => {
        if (!isSocketConnected || !authenticated) {
            return;
        }

        const onManualEnabled = (enabled) => {
            setManualModeEnabled(enabled);
        };

        socket.on("get_manualEnabled", onManualEnabled);

        socket.emit("get_manualEnabled");

        return () => {
            socket.off("get_manualEnabled", onManualEnabled);
        };
    }, [isSocketConnected, authenticated]);

    return (
        <Menu pageWrapId={"page-wrap"} outerContainerId={"outer-container"} {...props}>
            <div className='Sidebar logo-container rounded'>
                <img src={logo} width='50px'></img>
				<span className='Sidebar title'>Pensiunea</span>
                <span className='Sidebar title'>Erdélykincse</span>
                <span className='Sidebar title'>Panzió</span>
            </div>
            <div style={{ height: "30px" }}></div>
            <Link to='/activate'>
                <CustomButton text={"Activate sauna"} icon={<FaTicketAlt />} />
            </Link>
            <div className='mb-5' />
            {authenticated ? (
                <>
                    <Link to='/admin'>
                        <CustomButton text={"Kulcsok kezelése"} icon={<FaPlusCircle />} />
                    </Link>
                    <div className='mb-3' />
                    <Link to='/temperature'>
                        <CustomButton text={"Hőmérséklet beállítása"} icon={<FaTemperatureLow />} />
                    </Link>
                    <div className='mb-3' />
                    <Link to='/manual'>
                        <CustomButton
                            text={"Manuális üzemmód"}
                            icon={<FaToggleOn />}
                            variant={manualModeEnabled ? "hot" : "warm"}
                        />
                    </Link>
                    <div className='mb-5' />
                    <Link to='/admin'>
                        <CustomButton
                            text={"Kijelentkezés"}
                            icon={<FaSignOutAlt />}
                            onClick={() => socket.emit("logout")}
                        />
                    </Link>
                </>
            ) : (
                <>
                    <Link to='/login'>
                        <CustomButton text={"Admin"} icon={<FaKey />} />
                    </Link>
                </>
            )}
        </Menu>
    );
}
