import { useCallback, useContext, useState, useEffect } from "react";
import Moment from "react-moment";
import moment from "moment";
import { FaKey, FaCalendarPlus, FaStopwatch } from "react-icons/fa";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";

import Divider from "../Components/Divider";
import CustomButton from "../Components/CustomButton";
import { SocketContext } from "../Contexts/SocketContext";
import useLocalStorage from "../Hooks/useLocalStorage";

function IconWithText({ icon, text }) {
    return (
        <div className='d-flex justify-content-center align-items-center'>
            <div
                className='me-1 d-flex justify-content-center align-items-center'
                style={{ fontSize: "14px" }}
            >
                {icon}
            </div>
            {text}
        </div>
    );
}

function TicketRow({
    variant,
    ticket: key,
    duration,
    generationEpoch,
    onClick,
    className,
    activeTicket,
}) {
    const start = moment().add(-duration, "ms");
    const generationMoment = moment(generationEpoch);

    const isActive = activeTicket && activeTicket.ticket === key;
    const [end, setEnd] = useState(null);
    const [currentMoment, setCurrentMoment] = useState(moment());

    useEffect(() => {
        if (!activeTicket) return;
        setEnd(moment(activeTicket.activationEpoch).add(activeTicket.duration, "ms"));
        const interval = setInterval(() => {
            setCurrentMoment(moment());
        }, 1000);
        setCurrentMoment(moment());
        return () => {
            clearInterval(interval);
        };
    }, [activeTicket]);

    return (
        <CustomButton
            variant={variant}
            icon={<IconWithText icon={<FaKey />} text={key} />}
            indicator={
                <IconWithText
                    icon={<FaStopwatch />}
                    text={
                        isActive ? (
                            <Moment duration={currentMoment} date={end} format='hh:mm:ss' />
                        ) : (
                            <Moment date={start} format='hh:mm:ss' durationFromNow>
                                {duration}
                            </Moment>
                        )
                    }
                />
            }
            text={
                <IconWithText
                    icon={<FaCalendarPlus />}
                    text={<Moment format='YYYY/MM/DD'>{generationMoment}</Moment>}
                />
            }
            onClick={onClick}
            className={className}
        />
    );
}

function Input({ value, setValue, filter, title }) {
    return (
        <div className='d-flex flex-column' style={{ width: "100px" }}>
            <span>{title}</span>
            <input
                className='bg-transparent border rounded border-warm border-3 p-1 text-warm h3 text-center'
                value={value}
                onChange={(e) => filter(e.target.value) && setValue(parseInt(e.target.value) || 0)}
                maxLength='2'
            ></input>
        </div>
    );
}

function copyToClipbard(text) {
    const mixinTemplate = {
        customClass: {
            title: "text-light",
        },
        timer: 1500,
        showConfirmButton: false,
        toast: true,
        position: "top",
        background: "#303030",
    };

    const onSuccess = () => {
        Swal.fire({
            ...mixinTemplate,
            icon: "success",
            title: "Kulcs a vágólapra másolva",
            text: text,
        });
    };

    const onFail = () => {
        Swal.fire({
            ...mixinTemplate,
            icon: "error",
            title: "Kulcs vágólapra másolása sikertelen",
            text: text,
        });
    };

    if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(onSuccess, onFail);
    } else {
        onFail();
    }
}

export default function Admin() {
    const { socket } = useContext(SocketContext);

    const [hours, setHours] = useLocalStorage("admin-hours", 0);
    const [minutes, setMinutes] = useLocalStorage("admin-minutes", 30);
    const [seconds, setSeconds] = useLocalStorage("admin-seconds", 0);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [tickets, setTickets] = useState([]);
    const [activeTicket, setActiveTicket] = useState(null);
    const [loading, setLoading] = useState(true);

    const generateTicket = useCallback(() => {
        setLoading(true);
        socket.emit("generateTicket", (seconds + minutes * 60 + hours * 60 * 60) * 1000);
    }, [hours, minutes, seconds]);

    const removeTicket = useCallback(() => {
        setSelectedTicket(null);
        setLoading(true);
        socket.emit("removeTicket", selectedTicket.ticket);
    }, [selectedTicket]);

    useEffect(() => {
        const onTickets = (tickets) => {
            setSelectedTicket(null);
            setTickets(tickets);
            setLoading(false);
        };
        const onActiveTicket = (ticket) => {
            setActiveTicket(ticket);
        };
        const onDeactivate = () => {
            setActiveTicket(null);
        };

        socket.on("get_tickets", onTickets);
        socket.on("get_activeTicket", onActiveTicket);
        socket.on("deactivate", onDeactivate);

        socket.emit("get_tickets");
        socket.emit("get_activeTicket");

        return () => {
            socket.off("get_tickets", onTickets);
            socket.off("get_activeTicket", onActiveTicket);
            socket.off("deactivate", onDeactivate);
        };
    }, []);

    return (
        <>
            <div className='mb-4'>
                <Divider>Új kulcs generálása</Divider>
                <div className='d-flex justify-content-evenly mb-3'>
                    <Input
                        title={"Óra"}
                        value={hours}
                        setValue={setHours}
                        filter={(value) => value >= 0 && value <= 24}
                    />
                    <Input
                        title={"Perc"}
                        value={minutes}
                        setValue={setMinutes}
                        filter={(value) => value >= 0 && value <= 60}
                    />
                    <Input
                        title={"Másodperc"}
                        value={seconds}
                        setValue={setSeconds}
                        filter={(value) => value >= 0 && value <= 60}
                    />
                </div>
                <CustomButton text={"Generálás"} onClick={() => generateTicket()} />
            </div>
            <div>
                <Divider>Kulcs lista</Divider>
                <div
                    className='mb-4 rounded-3 p-2'
                    style={{
                        height: "250px",
                        overflow: "auto",
                        border: "2px solid #3c3c3c",
                    }}
                >
                    {loading ? (
                        <BeatLoader color={"white"} loading={loading} size={20} />
                    ) : (
                        <>
                            {activeTicket != null && (
                                <TicketRow
                                    {...activeTicket}
                                    className='mb-2'
                                    onClick={() => {
                                        const momentDuration = moment.duration(
                                                activeTicket.duration,
                                                "ms"
                                            ),
                                            hours = momentDuration.hours(),
                                            minutes = momentDuration.minutes(),
                                            seconds = momentDuration.seconds();
                                        setHours(hours);
                                        setMinutes(minutes);
                                        setSeconds(seconds);
                                        if (activeTicket === selectedTicket) {
                                            copyToClipbard(activeTicket.ticket);
                                        }
                                        setSelectedTicket(activeTicket);
                                    }}
                                    activeTicket={activeTicket}
                                    variant={
                                        selectedTicket === activeTicket
                                            ? "bg-warm outline-hot"
                                            : "outline-hot"
                                    }
                                />
                            )}
                            {tickets.map((ticket, i) => (
                                <TicketRow
                                    key={i}
                                    {...ticket}
                                    className='mb-2'
                                    onClick={() => {
                                        const momentDuration = moment.duration(
                                                ticket.duration,
                                                "ms"
                                            ),
                                            hours = momentDuration.hours(),
                                            minutes = momentDuration.minutes(),
                                            seconds = momentDuration.seconds();
                                        setHours(hours);
                                        setMinutes(minutes);
                                        setSeconds(seconds);
                                        if (ticket === selectedTicket) {
                                            copyToClipbard(ticket.ticket);
                                        }
                                        setSelectedTicket(ticket);
                                    }}
                                    activeTicket={activeTicket}
                                    variant={selectedTicket === ticket ? "warm" : "outline-warm"}
                                />
                            ))}
                        </>
                    )}
                </div>
                {(activeTicket != null || tickets.length > 0) && (
                    <CustomButton
                        text={
                            selectedTicket != null && selectedTicket === activeTicket
                                ? "Deaktiválás és törlés"
                                : "Törlés"
                        }
                        variant={selectedTicket == null ? "outline-hot" : "hot"}
                        onClick={() => selectedTicket != null && removeTicket()}
                    />
                )}
            </div>
        </>
    );
}
