import { useContext, useEffect, useState } from "react";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Divider from "../Components/Divider";
import { SocketContext } from "../Contexts/SocketContext";

export default function Temperature() {
    const { socket } = useContext(SocketContext);

    const [maxTemperature, setMaxTemperature] = useState(0);

    useEffect(() => {
        const onMaxTemperature = (maxTemperature) => {
            setMaxTemperature(maxTemperature);
        };

        socket.on("get_maxTemperature", onMaxTemperature);

        socket.emit("get_maxTemperature");

        return () => {
            socket.off("get_maxTemperature", onMaxTemperature);
        };
    }, []);

    return (
        <div>
            <Divider className='mb-0'>Maximum hőmérséklet</Divider>
            <span className='h3 text-secondary'>{maxTemperature}&#176;C</span>
            <div className='m-3'>
                <Slider
                    min={0}
                    max={120}
                    step={1}
                    value={maxTemperature}
                    onChange={setMaxTemperature}
                    onAfterChange={(value) => {
                        socket.emit("set_maxTemperature", value);
                    }}
                    defaultValue={120}
                    railStyle={{
                        background: "var(--hot)",
                        height: 10,
                        opacity: 0.6,
                    }}
                    handleStyle={{
                        borderColor: "red",
                        height: 22,
                        width: 22,
                        marginTop: -11 + 5,
                        backgroundColor: "#3c3c3c",
                        boxShadow: "none",
                        border: "2px solid #9d9d9d",
                    }}
                    trackStyle={{
                        backgroundColor: "var(--hot)",
                        height: 10,
                    }}
                />
            </div>
        </div>
    );
}
