import React, { useEffect, useState } from "react";
import "./Navbar.css";
import logo from "./logo.png";

export default function Navbar({ showOffcanvas }) {
    //const [scrollingUp, setScrollingUp] = useState(true);
    const scrollingUp = true;
    const [currentTime, setCurrentTime] = useState("");

    useEffect(() => {
        /*
        function onScroll() {
            setScrollingUp(this.oldScroll > this.scrollY);
            this.oldScroll = this.scrollY;
        }
        window.addEventListener('scroll', onScroll);
        */

        function updateClock() {
            const time = new Date();
            const hours = String(time.getHours()).padStart(2, "0");
            const minutes = String(time.getMinutes()).padStart(2, "0");
            const seconds = String(time.getSeconds()).padStart(2, "0");
            setCurrentTime(hours + ":" + minutes + ":" + seconds);
        }
        updateClock();
        const updateClockInterval = setInterval(updateClock, 1000);

        return () => {
            //window.removeEventListener('scroll', onScroll);
            clearInterval(updateClockInterval);
        };
    }, []);

    return (
        <div className={`Navbar Wrapper ${scrollingUp ? "scroll-up" : "scroll-down"}`}>
            <img
                src={logo}
                width='40px'
                height='40px'
                alt=''
                style={{ padding: "3px", marginRight: "5px" }}
            ></img>
            <span>Szauna</span>
        </div>
    );
}
