import { createContext, useContext, useEffect, useState } from "react";
import { SocketContext } from "./SocketContext";

const SessionContext = createContext();

function SessionProvider({ ...props }) {
    const { isSocketConnected, socket } = useContext(SocketContext);

    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        if (!isSocketConnected) {
            return;
        }

        const onIsAdmin = (isAdmin) => {
            setAuthenticated(isAdmin);
        };

        socket.emit("get_isAdmin");

        socket.on("get_isAdmin", onIsAdmin);

        return () => {
            socket.off("get_isAdmin", onIsAdmin);
        };
    }, [isSocketConnected]);

    return (
        <SessionContext.Provider value={{ authenticated, setAuthenticated }}>
            {props.children}
        </SessionContext.Provider>
    );
}

export { SessionContext, SessionProvider };
