const classes = {
    warm: "bg-warm text-dark border border-3 border-warm",
    hot: "bg-hot text-light border border-3 border-hot",
    "outline-warm": "bg-transparent border border-3 border-warm",
    "outline-hot": "bg-transparent border border-3 border-hot",
    "bg-warm outline-hot": "bg-warm text-dark border border-3 border-hot",
};

export default function CustomButton({
    text,
    icon,
    indicator,
    className,
    onClick,
    variant = "warm",
}) {
    return (
        <div
            className={`d-flex rounded-3 p-1 text-center ${classes[variant]} ${className}`}
            onClick={onClick}
            style={{ fontWeight: "500", cursor: "pointer" }}
        >
            <div className='d-flex col align-items-center justify-content-start'>{icon}</div>
            <div className='d-flex align-items-center justify-content-center'>{text}</div>
            <div className='d-flex col align-items-center justify-content-end'>{indicator}</div>
        </div>
    );
}
