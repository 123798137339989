import { useContext, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";

import "./App.css";
import Sidebar from "./Sidebar/Sidebar";
import Navbar from "./Navbar/Navbar";
import { SocketContext } from "./Contexts/SocketContext";
import { SessionContext, SessionProvider } from "./Contexts/SessionContext";
import useWindowSize from "./Hooks/useWindowSize";

import Activate from "./Routes/Activate";
import Login from "./Routes/Login";
import Admin from "./Routes/Admin";
import Temperature from "./Routes/Temperature";
import Manual from "./Routes/Manual";
import Logout from "./Routes/Logout";

const connectingToast = {
    customClass: {
        title: "text-light",
    },
    showConfirmButton: false,
    background: "#303030",
    text: "If the connection takes more than a few seconds, reload the page or contact the administrator for help.",
    icon: "info",
    didOpen: (a, b) => {
        Swal.showLoading();
    },
    allowOutsideClick: false,
    backdrop: "rgba(0, 0, 0, 0.8)",
    iconColor: "var(--warm)",
};

function AdminMenu({ ...props }) {
    const { authenticated } = useContext(SessionContext);
    return <>{authenticated ? <>{props.children}</> : <Navigate to='/' />}</>;
}

export default function App() {
    const { isSocketConnected } = useContext(SocketContext);
    const [wasSocketConnectedOnce, setWasSocketConnectedOnce] = useState(false);
    const [showPage, setShowPage] = useState(false);

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });

    const windowSize = useWindowSize();

    useEffect(() => {
        if (isSocketConnected) {
            if (!wasSocketConnectedOnce) {
                setTimeout(Swal.close, 1500);
                setTimeout(() => setShowPage(true), 1500);
            } else {
                Swal.close();
                setShowPage(true);
            }
        } else {
            setShowPage(false);
            if (wasSocketConnectedOnce) {
                Swal.fire({ ...connectingToast, title: "Connection lost. Reconnecting..." });
            } else {
                Swal.fire({ ...connectingToast, title: "Connecting..." });
            }
        }
        if (isSocketConnected && !wasSocketConnectedOnce) {
            setWasSocketConnectedOnce(true);
        }
    }, [isSocketConnected]);

    return (
        <div id='outer-container'>
            <SessionProvider>
                <Sidebar
                    isOpen={isDesktopOrLaptop ? true : false}
                    noOverlay={isDesktopOrLaptop}
                    crossButtonClassName={isDesktopOrLaptop ? "d-none" : ""}
                />
                <main id='page-wrap' style={{ width: isDesktopOrLaptop && windowSize.width - 300 }}>
                    <Navbar />
                    <div id='Page'>
                        <div id='Page-Center'>
                            {showPage && (
                                <Routes>
                                    <Route path='/' element={<Activate />} />

                                    <Route path='/activate' element={<Activate />} />
                                    <Route path='/login' element={<Login />} />

                                    <Route
                                        path='/admin'
                                        element={
                                            <AdminMenu>
                                                <Admin />
                                            </AdminMenu>
                                        }
                                    />
                                    <Route
                                        path='/temperature'
                                        element={
                                            <AdminMenu>
                                                <Temperature />
                                            </AdminMenu>
                                        }
                                    />
                                    <Route
                                        path='/manual'
                                        element={
                                            <AdminMenu>
                                                <Manual />
                                            </AdminMenu>
                                        }
                                    />
                                    <Route
                                        path='/logout'
                                        element={
                                            <AdminMenu>
                                                <Logout />
                                            </AdminMenu>
                                        }
                                    />

                                    <Route path='*' element={<Navigate to='/' />} />
                                </Routes>
                            )}
                        </div>
                    </div>
                </main>
            </SessionProvider>
        </div>
    );
}
