import { useContext, useState, useEffect } from "react";
import Moment from "react-moment";
import moment from "moment";
import Swal from "sweetalert2";

import { SocketContext } from "../Contexts/SocketContext";
import Divider from "../Components/Divider";
import CustomButton from "../Components/CustomButton";

const mixinTemplate = {
    customClass: {
        title: "text-light",
    },
    timer: 1500,
    showConfirmButton: false,
    toast: true,
    position: "top",
    background: "#303030",
};

export default function Activate() {
    const { socket } = useContext(SocketContext);

    const [input, setInput] = useState("");

    const [activated, setActivated] = useState(false);
    const [duration, setDuration] = useState(null);
    const [temperature, setTemperature] = useState(null);
    const [ticket, setTicket] = useState(null);
    const [activationEpoch, setActivationEpoch] = useState(null);
    const [currentMoment, setCurrentMoment] = useState(moment());
    const [end, setEnd] = useState(moment(activationEpoch).add(duration, "ms"));

    useEffect(() => {
        setEnd(moment(activationEpoch).add(duration, "ms"));
    }, [duration, activationEpoch]);

    useEffect(() => {
        var interval;
        if (activated) {
            setCurrentMoment(moment());
            interval = setInterval(() => setCurrentMoment(moment()), 1000);
        }
        return () => clearInterval(interval);
    }, [activated]);

    useEffect(() => {
        const onIsActive = (isActive) => {
            if (isActive) {
                socket.emit("get_activeTicket");
            }
        };

        const onActiveKeyData = ({ duration, ticket, activationEpoch }) => {
            setActivated(true);
            setDuration(duration);
            setTicket(ticket);
            setActivationEpoch(activationEpoch);
        };

        const onTemperature = (temperature) => {
            setTemperature(temperature);
        };

        const handleActivation = ({ success, message }) => {
            if (success) {
                Swal.fire({ ...mixinTemplate, title: "Sauna activated", icon: "success" });
                socket.emit("get_activeTicket");
            } else {
                Swal.fire({ ...mixinTemplate, icon: "error", title: "Error", text: message });
            }
            setActivated(success);
        };

        const onDeactivate = (success) => {
            if (success) {
                Swal.fire({ ...mixinTemplate, title: "Sauna deactivated", icon: "warning" });
                setActivated(false);
            }
        };

        const onTicketExpired = () => {
            Swal.fire({ ...mixinTemplate, icon: "warning", title: "The ticket has expired." });
            setActivated(false);
        };

        socket.on("get_isActive", onIsActive);
        socket.on("activate", handleActivation);
        socket.on("deactivate", onDeactivate);
        socket.on("get_activeTicket", onActiveKeyData);
        socket.on("get_temperature", onTemperature);
        socket.on("ticketExpired", onTicketExpired);

        socket.emit("get_isActive");

        return () => {
            socket.off("get_isActive", onIsActive);
            socket.off("activate", handleActivation);
            socket.off("deactivate", onDeactivate);
            socket.off("get_activeTicket", onActiveKeyData);
            socket.off("get_temperature", onTemperature);
            socket.off("ticketExpired", onTicketExpired);
        };
    }, []);

    return (
        <>
            <div>
                {activated ? (
                    <>
                        <Divider className='mb-0'>Time left</Divider>
                        <span className='h3 text-secondary'>
                            <Moment duration={currentMoment} date={end} format='hh:mm:ss' />
                        </span>

                        <Divider className='mb-0'>Temperature</Divider>
                        <span className='h3 text-secondary'>{temperature}&#176;C</span>

                        <Divider className='mb-0'>Active ticket</Divider>
                        <span className='h3 text-secondary'>{ticket}</span>

                        <Divider className='mb-0'>Activation date</Divider>
                        <span className='h3 text-secondary'>
                            <Moment format='YYYY/MM/DD hh:mm:ss'>{moment(activationEpoch)}</Moment>
                        </span>

                        <div className='mb-5'></div>

                        <CustomButton
                            text={"Deactivate"}
                            onClick={() => socket.emit("deactivate")}
                        ></CustomButton>
                    </>
                ) : (
                    <>
                        <Divider>Activate sauna</Divider>
                        <div className='mx-3'>
                            <input
                                className='bg-transparent border rounded border-warm border-3 p-1 text-warm h3 text-center mb-4'
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder='Ticket'
                                maxLength={8}
                            ></input>
                            <CustomButton
                                text={"Activate"}
                                hot={true}
                                onClick={() => input.length > 0 && socket.emit("activate", input)}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
