import { useContext, useEffect, useState } from "react";

import CustomButton from "../Components/CustomButton";
import Divider from "../Components/Divider";
import { SocketContext } from "../Contexts/SocketContext";

export default function Manual() {
    const { socket } = useContext(SocketContext);

    const [enabled, setEnabled] = useState(false);
    const [state, setState] = useState(false);

    useEffect(() => {
        const onManualEnabled = (enabled) => {
            setEnabled(enabled);
        };

        const onManualState = (state) => {
            setState(state);
        };

        socket.on("get_manualEnabled", onManualEnabled);
        socket.on("get_manualState", onManualState);

        socket.emit("get_manualEnabled");
        socket.emit("get_manualState");

        return () => {
            socket.off("get_manualEnabled", onManualEnabled);
            socket.off("get_manualState", onManualState);
        };
    }, []);

    return (
        <>
            <Divider className='mb-0'>Manuális üzemmód</Divider>
            <span className='h3 text-secondary'>
                <b style={{ color: enabled ? "green" : "darkred" }}>{enabled ? "ON" : "OFF"}</b>
            </span>
            <div className='m-3'>
                <CustomButton
                    text={enabled ? "Letiltás" : "Engedélyezés"}
                    hot={true}
                    onClick={() => socket.emit("set_manualEnabled", !enabled)}
                />
            </div>

            {enabled && (
                <>
                    <Divider className='mb-0'>Állapot</Divider>
                    <span className='h3 text-secondary'>
                        <b style={{ color: state ? "green" : "darkred" }}>{state ? "ON" : "OFF"}</b>
                    </span>
                    <div className='m-3'>
                        <CustomButton
                            text={state ? "Kikapcsolás" : "Bekapcsolás"}
                            warm={true}
                            onClick={() => socket.emit("set_manualState", !state)}
                        />
                    </div>
                </>
            )}
        </>
    );
}
