import React, { createContext, useEffect, useState } from "react";
import io from "socket.io-client";

const TOKEN = "hP3eJh4HIx50xamr";
const URL = window.location.protocol !== "https:" ? `${window.location.hostname}:3001` : "";

const SocketContext = createContext();

function SocketProvider({ ...props }) {
    const [isSocketConnected, setIsSocketConnected] = useState(false);
    const [socket, setSocket] = useState();

    useEffect(() => {
        const newSocket = io(URL, {
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: Infinity,
            auth: {
                token: TOKEN,
            },
        });
        newSocket.on("connect", () => {
            setIsSocketConnected(true);
        });

        newSocket.on("disconnect", (reason) => {
            console.log("Socket disconnected:", reason);
            setIsSocketConnected(false);
            newSocket.connect();
        });
        setSocket(newSocket);
        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {}, []);

    return (
        <SocketContext.Provider value={{ socket, isSocketConnected }}>
            {props.children}
        </SocketContext.Provider>
    );
}

export { SocketContext, SocketProvider };
